.player {
    background-image: url("#{$img-path}/players/players.png"); 
    width: 150px;
    height: 150px;
    background-repeat: no-repeat;  
    display: inline-block;
    vertical-align: middle;
} 
.player.p-840 { background-position: 0 0; } 
.player.p-854 { background-position: 0 -150px; } 
.player.p-912 { background-position: 0 -300px; } 
.player.p-947 { background-position: 0 -450px; } 
.player.p-1140 { background-position: 0 -600px; } 
.player.p-1272 { background-position: 0 -750px; } 
.player.p-1308 { background-position: 0 -900px; } 
.player.p-1603 { background-position: 0 -1050px; } 
.player.p-1641 { background-position: 0 -1200px; } 
.player.p-1670 { background-position: 0 -1350px; } 
.player.p-1689 { background-position: 0 -1500px; } 
.player.p-2656 { background-position: 0 -1650px; } 
.player.p-2710 { background-position: 0 -1800px; } 
.player.p-2950 { background-position: 0 -1950px; } 
.player.p-3636 { background-position: 0 -2100px; } 
.player.p-6449 { background-position: 0 -2250px; } 
.player.p-6538 { background-position: 0 -2400px; } 
.player.p-6571 { background-position: 0 -2550px; } 
.player.p-6707 { background-position: 0 -2700px; } 
.player.p-6773 { background-position: 0 -2850px; } 
.player.p-8619 { background-position: 0 -3000px; } 
.player.p-8633 { background-position: 0 -3150px; } 
.player.p-8644 { background-position: 0 -3300px; } 
.player.p-10143 { background-position: 0 -3450px; } 
.player.p-11889 { background-position: 0 -3600px; } 
.player.p-13051 { background-position: 0 -3750px; } 
.player.p-13522 { background-position: 0 -3900px; } 
.player.p-13642 { background-position: 0 -4050px; } 
.player.p-13644 { background-position: 0 -4200px; } 
.player.p-13654 { background-position: 0 -4350px; } 
.player.p-13697 { background-position: 0 -4500px; } 
.player.p-13951 { background-position: 0 -4650px; } 
.player.p-13959 { background-position: 0 -4800px; } 
.player.p-14027 { background-position: 0 -4950px; } 
.player.p-14556 { background-position: 0 -5100px; } 
.player.p-14809 { background-position: 0 -5250px; } 
.player.p-14824 { background-position: 0 -5400px; } 
.player.p-14862 { background-position: 0 -5550px; } 
.player.p-14878 { background-position: 0 -5700px; } 
.player.p-15540 { background-position: 0 -5850px; } 
.player.p-15828 { background-position: 0 -6000px; } 
.player.p-16023 { background-position: 0 -6150px; } 
.player.p-16042 { background-position: 0 -6300px; } 
.player.p-16065 { background-position: 0 -6450px; } 
.player.p-16624 { background-position: 0 -6600px; } 
.player.p-17017 { background-position: 0 -6750px; } 
.player.p-17303 { background-position: 0 -6900px; } 
.player.p-17304 { background-position: 0 -7050px; } 
.player.p-18385 { background-position: 0 -7200px; } 
.player.p-18684 { background-position: 0 -7350px; } 
.player.p-18734 { background-position: 0 -7500px; } 
.player.p-18763 { background-position: 0 -7650px; } 
.player.p-18922 { background-position: 0 -7800px; } 
.player.p-18941 { background-position: 0 -7950px; } 
.player.p-19075 { background-position: 0 -8100px; } 
.player.p-19114 { background-position: 0 -8250px; } 
.player.p-19144 { background-position: 0 -8400px; } 
.player.p-19205 { background-position: 0 -8550px; } 
.player.p-19484 { background-position: 0 -8700px; } 
.player.p-19875 { background-position: 0 -8850px; } 
.player.p-20129 { background-position: 0 -9000px; } 
.player.p-20133 { background-position: 0 -9150px; } 
.player.p-20537 { background-position: 0 -9300px; } 
.player.p-20731 { background-position: 0 -9450px; } 
.player.p-21033 { background-position: 0 -9600px; } 
.player.p-21091 { background-position: 0 -9750px; } 
.player.p-21130 { background-position: 0 -9900px; } 
.player.p-21148 { background-position: 0 -10050px; } 
.player.p-21206 { background-position: 0 -10200px; } 
.player.p-21351 { background-position: 0 -10350px; } 
.player.p-21770 { background-position: 0 -10500px; } 
.player.p-22367 { background-position: 0 -10650px; } 
.player.p-22463 { background-position: 0 -10800px; } 
.player.p-22492 { background-position: 0 -10950px; } 
.player.p-22494 { background-position: 0 -11100px; } 
.player.p-22817 { background-position: 0 -11250px; } 
.player.p-22832 { background-position: 0 -11400px; } 
.player.p-23111 { background-position: 0 -11550px; } 
.player.p-23113 { background-position: 0 -11700px; } 
.player.p-23284 { background-position: 0 -11850px; } 
.player.p-23459 { background-position: 0 -12000px; } 
.player.p-23644 { background-position: 0 -12150px; } 
.player.p-23848 { background-position: 0 -12300px; } 
.player.p-23866 { background-position: 0 -12450px; } 
.player.p-24048 { background-position: 0 -12600px; } 
.player.p-24407 { background-position: 0 -12750px; } 
.player.p-24952 { background-position: 0 -12900px; } 
.player.p-25241 { background-position: 0 -13050px; } 
.player.p-25345 { background-position: 0 -13200px; } 
.player.p-25347 { background-position: 0 -13350px; } 
.player.p-25432 { background-position: 0 -13500px; } 
.player.p-25438 { background-position: 0 -13650px; } 
.player.p-26420 { background-position: 0 -13800px; } 
.player.p-26482 { background-position: 0 -13950px; } 
.player.p-26526 { background-position: 0 -14100px; } 
.player.p-26851 { background-position: 0 -14250px; } 
.player.p-27360 { background-position: 0 -14400px; } 
.player.p-27437 { background-position: 0 -14550px; } 
.player.p-27670 { background-position: 0 -14700px; } 
.player.p-28296 { background-position: 0 -14850px; } 
.player.p-28383 { background-position: 0 -15000px; } 
.player.p-28614 { background-position: 0 -15150px; } 
.player.p-29848 { background-position: 0 -15300px; } 
.player.p-29945 { background-position: 0 -15450px; } 
.player.p-29947 { background-position: 0 -15600px; } 
.player.p-29981 { background-position: 0 -15750px; } 
.player.p-30826 { background-position: 0 -15900px; } 
.player.p-31239 { background-position: 0 -16050px; } 
.player.p-31398 { background-position: 0 -16200px; } 
.player.p-31407 { background-position: 0 -16350px; } 
.player.p-31567 { background-position: 0 -16500px; } 
.player.p-32214 { background-position: 0 -16650px; } 
.player.p-32306 { background-position: 0 -16800px; } 
.player.p-32324 { background-position: 0 -16950px; } 
.player.p-33400 { background-position: 0 -17100px; } 
.player.p-33466 { background-position: 0 -17250px; } 
.player.p-33496 { background-position: 0 -17400px; } 
.player.p-34050 { background-position: 0 -17550px; } 
.player.p-34137 { background-position: 0 -17700px; } 
.player.p-34181 { background-position: 0 -17850px; } 
.player.p-34438 { background-position: 0 -18000px; } 
.player.p-34505 { background-position: 0 -18150px; } 
.player.p-34659 { background-position: 0 -18300px; } 
.player.p-34682 { background-position: 0 -18450px; } 
.player.p-34922 { background-position: 0 -18600px; } 
.player.p-34969 { background-position: 0 -18750px; } 
.player.p-35144 { background-position: 0 -18900px; } 
.player.p-35230 { background-position: 0 -19050px; } 
.player.p-35233 { background-position: 0 -19200px; } 
.player.p-35287 { background-position: 0 -19350px; } 
.player.p-35323 { background-position: 0 -19500px; } 
.player.p-36041 { background-position: 0 -19650px; } 
.player.p-36633 { background-position: 0 -19800px; } 
.player.p-37373 { background-position: 0 -19950px; } 
.player.p-37407 { background-position: 0 -20100px; } 
.player.p-37422 { background-position: 0 -20250px; } 
.player.p-37946 { background-position: 0 -20400px; } 
.player.p-38681 { background-position: 0 -20550px; } 
.player.p-38703 { background-position: 0 -20700px; } 
.player.p-38718 { background-position: 0 -20850px; } 
.player.p-38730 { background-position: 0 -21000px; } 
.player.p-38740 { background-position: 0 -21150px; } 
.player.p-38816 { background-position: 0 -21300px; } 
.player.p-39048 { background-position: 0 -21450px; } 
.player.p-39077 { background-position: 0 -21600px; } 
.player.p-39119 { background-position: 0 -21750px; } 
.player.p-39157 { background-position: 0 -21900px; } 
.player.p-39193 { background-position: 0 -22050px; } 
.player.p-39287 { background-position: 0 -22200px; } 
.player.p-39372 { background-position: 0 -22350px; } 
.player.p-39552 { background-position: 0 -22500px; } 
.player.p-39736 { background-position: 0 -22650px; } 
.player.p-39820 { background-position: 0 -22800px; } 
.player.p-39965 { background-position: 0 -22950px; } 
.player.p-40509 { background-position: 0 -23100px; } 
.player.p-40512 { background-position: 0 -23250px; } 
.player.p-40620 { background-position: 0 -23400px; } 
.player.p-41131 { background-position: 0 -23550px; } 
.player.p-41138 { background-position: 0 -23700px; } 
.player.p-41150 { background-position: 0 -23850px; } 
.player.p-41268 { background-position: 0 -24000px; } 
.player.p-41474 { background-position: 0 -24150px; } 
.player.p-41533 { background-position: 0 -24300px; } 
.player.p-41537 { background-position: 0 -24450px; } 
.player.p-41574 { background-position: 0 -24600px; } 
.player.p-42264 { background-position: 0 -24750px; } 
.player.p-42265 { background-position: 0 -24900px; } 
.player.p-42665 { background-position: 0 -25050px; } 
.player.p-42699 { background-position: 0 -25200px; } 
.player.p-43645 { background-position: 0 -25350px; } 
.player.p-43908 { background-position: 0 -25500px; } 
.player.p-44419 { background-position: 0 -25650px; } 
.player.p-44980 { background-position: 0 -25800px; } 
.player.p-45538 { background-position: 0 -25950px; } 
.player.p-45932 { background-position: 0 -26100px; } 
.player.p-45941 { background-position: 0 -26250px; } 
.player.p-45993 { background-position: 0 -26400px; } 
.player.p-46093 { background-position: 0 -26550px; } 
.player.p-46528 { background-position: 0 -26700px; } 
.player.p-46534 { background-position: 0 -26850px; } 
.player.p-46589 { background-position: 0 -27000px; } 
.player.p-46665 { background-position: 0 -27150px; } 
.player.p-46997 { background-position: 0 -27300px; } 
.player.p-47158 { background-position: 0 -27450px; } 
.player.p-47223 { background-position: 0 -27600px; } 
.player.p-47330 { background-position: 0 -27750px; } 
.player.p-47526 { background-position: 0 -27900px; } 
.player.p-47567 { background-position: 0 -28050px; } 
.player.p-47752 { background-position: 0 -28200px; } 
.player.p-47871 { background-position: 0 -28350px; } 
.player.p-48221 { background-position: 0 -28500px; } 
.player.p-48239 { background-position: 0 -28650px; } 
.player.p-48281 { background-position: 0 -28800px; } 
.player.p-48474 { background-position: 0 -28950px; } 
.player.p-48539 { background-position: 0 -29100px; } 
.player.p-48630 { background-position: 0 -29250px; } 
.player.p-48662 { background-position: 0 -29400px; } 
.player.p-48774 { background-position: 0 -29550px; } 
.player.p-48806 { background-position: 0 -29700px; } 
.player.p-48821 { background-position: 0 -29850px; } 
.player.p-48925 { background-position: 0 -30000px; } 
.player.p-50185 { background-position: 0 -30150px; } 
.player.p-50199 { background-position: 0 -30300px; } 
.player.p-50494 { background-position: 0 -30450px; } 
.player.p-50749 { background-position: 0 -30600px; } 
.player.p-51515 { background-position: 0 -30750px; } 
.player.p-51802 { background-position: 0 -30900px; } 
.player.p-52080 { background-position: 0 -31050px; } 
.player.p-52081 { background-position: 0 -31200px; } 
.player.p-52230 { background-position: 0 -31350px; } 
.player.p-52251 { background-position: 0 -31500px; } 
.player.p-52313 { background-position: 0 -31650px; } 
.player.p-52503 { background-position: 0 -31800px; } 
.player.p-52573 { background-position: 0 -31950px; } 
.player.p-52648 { background-position: 0 -32100px; } 
.player.p-52722 { background-position: 0 -32250px; } 
.player.p-52992 { background-position: 0 -32400px; } 
.player.p-52998 { background-position: 0 -32550px; } 
.player.p-53007 { background-position: 0 -32700px; } 
.player.p-53126 { background-position: 0 -32850px; } 
.player.p-53127 { background-position: 0 -33000px; } 
.player.p-53299 { background-position: 0 -33150px; } 
.player.p-53498 { background-position: 0 -33300px; } 
.player.p-53554 { background-position: 0 -33450px; } 
.player.p-53566 { background-position: 0 -33600px; } 
.player.p-53920 { background-position: 0 -33750px; } 
.player.p-54125 { background-position: 0 -33900px; } 
.player.p-54553 { background-position: 0 -34050px; } 
.player.p-54668 { background-position: 0 -34200px; } 
.player.p-54673 { background-position: 0 -34350px; } 
.player.p-55711 { background-position: 0 -34500px; } 
.player.p-55824 { background-position: 0 -34650px; } 
.player.p-55953 { background-position: 0 -34800px; } 
.player.p-56057 { background-position: 0 -34950px; } 
.player.p-56386 { background-position: 0 -35100px; } 
.player.p-56873 { background-position: 0 -35250px; } 
.player.p-57591 { background-position: 0 -35400px; } 
.player.p-57592 { background-position: 0 -35550px; } 
.player.p-57872 { background-position: 0 -35700px; } 
.player.p-58842 { background-position: 0 -35850px; } 
.player.p-59115 { background-position: 0 -36000px; } 
.player.p-59164 { background-position: 0 -36150px; } 
.player.p-59287 { background-position: 0 -36300px; } 
.player.p-59292 { background-position: 0 -36450px; } 
.player.p-59549 { background-position: 0 -36600px; } 
.player.p-59643 { background-position: 0 -36750px; } 
.player.p-59960 { background-position: 0 -36900px; } 
.player.p-59965 { background-position: 0 -37050px; } 
.player.p-59966 { background-position: 0 -37200px; } 
.player.p-59972 { background-position: 0 -37350px; } 
.player.p-59977 { background-position: 0 -37500px; } 
.player.p-60242 { background-position: 0 -37650px; } 
.player.p-60246 { background-position: 0 -37800px; } 
.player.p-60345 { background-position: 0 -37950px; } 
.player.p-60357 { background-position: 0 -38100px; } 
.player.p-60384 { background-position: 0 -38250px; } 
.player.p-60482 { background-position: 0 -38400px; } 
.player.p-60684 { background-position: 0 -38550px; } 
.player.p-61101 { background-position: 0 -38700px; } 
.player.p-61362 { background-position: 0 -38850px; } 
.player.p-61372 { background-position: 0 -39000px; } 
.player.p-61376 { background-position: 0 -39150px; } 
.player.p-61416 { background-position: 0 -39300px; } 
.player.p-61641 { background-position: 0 -39450px; } 
.player.p-61800 { background-position: 0 -39600px; } 
.player.p-62360 { background-position: 0 -39750px; } 
.player.p-62644 { background-position: 0 -39900px; } 
.player.p-62975 { background-position: 0 -40050px; } 
.player.p-63079 { background-position: 0 -40200px; } 
.player.p-63287 { background-position: 0 -40350px; } 
.player.p-65227 { background-position: 0 -40500px; } 
.player.p-65233 { background-position: 0 -40650px; } 
.player.p-65279 { background-position: 0 -40800px; } 
.player.p-65578 { background-position: 0 -40950px; } 
.player.p-65890 { background-position: 0 -41100px; } 
.player.p-66058 { background-position: 0 -41250px; } 
.player.p-66253 { background-position: 0 -41400px; } 
.player.p-66626 { background-position: 0 -41550px; } 
.player.p-66633 { background-position: 0 -41700px; } 
.player.p-67073 { background-position: 0 -41850px; } 
.player.p-67269 { background-position: 0 -42000px; } 
.player.p-67700 { background-position: 0 -42150px; } 
.player.p-68204 { background-position: 0 -42300px; } 
.player.p-69140 { background-position: 0 -42450px; } 
.player.p-69692 { background-position: 0 -42600px; } 
.player.p-69882 { background-position: 0 -42750px; } 
.player.p-70102 { background-position: 0 -42900px; } 
.player.p-70277 { background-position: 0 -43050px; } 
.player.p-70557 { background-position: 0 -43200px; } 
.player.p-70625 { background-position: 0 -43350px; } 
.player.p-70849 { background-position: 0 -43500px; } 
.player.p-71042 { background-position: 0 -43650px; } 
.player.p-71117 { background-position: 0 -43800px; } 
.player.p-71223 { background-position: 0 -43950px; } 
.player.p-71386 { background-position: 0 -44100px; } 
.player.p-71635 { background-position: 0 -44250px; } 
.player.p-71973 { background-position: 0 -44400px; } 
.player.p-71974 { background-position: 0 -44550px; } 
.player.p-71976 { background-position: 0 -44700px; } 
.player.p-72245 { background-position: 0 -44850px; } 
.player.p-72266 { background-position: 0 -45000px; } 
.player.p-72534 { background-position: 0 -45150px; } 
.player.p-73009 { background-position: 0 -45300px; } 
.player.p-73015 { background-position: 0 -45450px; } 
.player.p-73362 { background-position: 0 -45600px; } 
.player.p-73406 { background-position: 0 -45750px; } 
.player.p-73461 { background-position: 0 -45900px; } 
.player.p-74162 { background-position: 0 -46050px; } 
.player.p-74648 { background-position: 0 -46200px; } 
.player.p-74669 { background-position: 0 -46350px; } 
.player.p-74767 { background-position: 0 -46500px; } 
.player.p-74772 { background-position: 0 -46650px; } 
.player.p-75011 { background-position: 0 -46800px; } 
.player.p-75274 { background-position: 0 -46950px; } 
.player.p-75519 { background-position: 0 -47100px; } 
.player.p-75711 { background-position: 0 -47250px; } 
.player.p-75742 { background-position: 0 -47400px; } 
.player.p-75976 { background-position: 0 -47550px; } 
.player.p-76351 { background-position: 0 -47700px; } 
.player.p-76505 { background-position: 0 -47850px; } 
.player.p-76552 { background-position: 0 -48000px; } 
.player.p-76563 { background-position: 0 -48150px; } 
.player.p-76564 { background-position: 0 -48300px; } 
.player.p-76615 { background-position: 0 -48450px; } 
.player.p-76698 { background-position: 0 -48600px; } 
.player.p-76720 { background-position: 0 -48750px; } 
.player.p-76904 { background-position: 0 -48900px; } 
.player.p-77151 { background-position: 0 -49050px; } 
.player.p-77538 { background-position: 0 -49200px; } 
.player.p-77588 { background-position: 0 -49350px; } 
.player.p-77979 { background-position: 0 -49500px; } 
.player.p-78200 { background-position: 0 -49650px; } 
.player.p-78804 { background-position: 0 -49800px; } 
.player.p-78822 { background-position: 0 -49950px; } 
.player.p-79039 { background-position: 0 -50100px; } 
.player.p-79072 { background-position: 0 -50250px; } 
.player.p-80428 { background-position: 0 -50400px; } 
.player.p-80711 { background-position: 0 -50550px; } 
.player.p-80971 { background-position: 0 -50700px; } 
.player.p-81774 { background-position: 0 -50850px; } 
.player.p-81778 { background-position: 0 -51000px; } 
.player.p-81789 { background-position: 0 -51150px; } 
.player.p-81802 { background-position: 0 -51300px; } 
.player.p-82602 { background-position: 0 -51450px; } 
.player.p-83247 { background-position: 0 -51600px; } 
.player.p-83870 { background-position: 0 -51750px; } 
.player.p-83932 { background-position: 0 -51900px; } 
.player.p-84862 { background-position: 0 -52050px; } 
.player.p-84863 { background-position: 0 -52200px; } 
.player.p-85050 { background-position: 0 -52350px; } 
.player.p-85184 { background-position: 0 -52500px; } 
.player.p-85326 { background-position: 0 -52650px; } 
.player.p-85339 { background-position: 0 -52800px; } 
.player.p-85348 { background-position: 0 -52950px; } 
.player.p-86606 { background-position: 0 -53100px; } 
.player.p-86669 { background-position: 0 -53250px; } 
.player.p-86880 { background-position: 0 -53400px; } 
.player.p-86934 { background-position: 0 -53550px; } 
.player.p-87150 { background-position: 0 -53700px; } 
.player.p-87663 { background-position: 0 -53850px; } 
.player.p-88031 { background-position: 0 -54000px; } 
.player.p-88216 { background-position: 0 -54150px; } 
.player.p-88217 { background-position: 0 -54300px; } 
.player.p-88423 { background-position: 0 -54450px; } 
.player.p-88484 { background-position: 0 -54600px; } 
.player.p-88530 { background-position: 0 -54750px; } 
.player.p-89126 { background-position: 0 -54900px; } 
.player.p-89568 { background-position: 0 -55050px; } 
.player.p-90845 { background-position: 0 -55200px; } 
.player.p-90881 { background-position: 0 -55350px; } 
.player.p-91096 { background-position: 0 -55500px; } 
.player.p-91100 { background-position: 0 -55650px; } 
.player.p-91107 { background-position: 0 -55800px; } 
.player.p-91120 { background-position: 0 -55950px; } 
.player.p-91422 { background-position: 0 -56100px; } 
.player.p-91679 { background-position: 0 -56250px; } 
.player.p-91731 { background-position: 0 -56400px; } 
.player.p-91977 { background-position: 0 -56550px; } 
.player.p-92066 { background-position: 0 -56700px; } 
.player.p-92286 { background-position: 0 -56850px; } 
.player.p-92336 { background-position: 0 -57000px; } 
.player.p-92855 { background-position: 0 -57150px; } 
.player.p-92968 { background-position: 0 -57300px; } 
.player.p-93045 { background-position: 0 -57450px; } 
.player.p-93136 { background-position: 0 -57600px; } 
.player.p-93207 { background-position: 0 -57750px; } 
.player.p-93417 { background-position: 0 -57900px; } 
.player.p-93852 { background-position: 0 -58050px; } 
.player.p-94760 { background-position: 0 -58200px; } 
.player.p-94942 { background-position: 0 -58350px; } 
.player.p-96182 { background-position: 0 -58500px; } 
.player.p-96361 { background-position: 0 -58650px; } 
.player.p-98053 { background-position: 0 -58800px; } 
.player.p-98200 { background-position: 0 -58950px; } 
.player.p-98406 { background-position: 0 -59100px; } 
.player.p-98539 { background-position: 0 -59250px; } 
.player.p-98811 { background-position: 0 -59400px; } 
.player.p-99176 { background-position: 0 -59550px; } 
.player.p-99242 { background-position: 0 -59700px; } 
.player.p-99422 { background-position: 0 -59850px; } 
.player.p-99423 { background-position: 0 -60000px; } 
.player.p-99485 { background-position: 0 -60150px; } 
.player.p-99685 { background-position: 0 -60300px; } 
.player.p-100102 { background-position: 0 -60450px; } 
.player.p-100186 { background-position: 0 -60600px; } 
.player.p-100227 { background-position: 0 -60750px; } 
.player.p-100272 { background-position: 0 -60900px; } 
.player.p-100283 { background-position: 0 -61050px; } 
.player.p-100484 { background-position: 0 -61200px; } 
.player.p-101239 { background-position: 0 -61350px; } 
.player.p-101318 { background-position: 0 -61500px; } 
.player.p-101789 { background-position: 0 -61650px; } 
.player.p-102949 { background-position: 0 -61800px; } 
.player.p-102963 { background-position: 0 -61950px; } 
.player.p-103171 { background-position: 0 -62100px; } 
.player.p-103387 { background-position: 0 -62250px; } 
.player.p-103538 { background-position: 0 -62400px; } 
.player.p-104030 { background-position: 0 -62550px; } 
.player.p-104247 { background-position: 0 -62700px; } 
.player.p-105192 { background-position: 0 -62850px; } 
.player.p-105791 { background-position: 0 -63000px; } 
.player.p-105973 { background-position: 0 -63150px; } 
.player.p-107016 { background-position: 0 -63300px; } 
.player.p-107025 { background-position: 0 -63450px; } 
.player.p-107848 { background-position: 0 -63600px; } 
.player.p-108045 { background-position: 0 -63750px; } 
.player.p-108187 { background-position: 0 -63900px; } 
.player.p-108833 { background-position: 0 -64050px; } 
.player.p-108834 { background-position: 0 -64200px; } 
.player.p-108872 { background-position: 0 -64350px; } 
.player.p-108874 { background-position: 0 -64500px; } 
.player.p-108875 { background-position: 0 -64650px; } 
.player.p-111144 { background-position: 0 -64800px; } 
.player.p-111154 { background-position: 0 -64950px; } 