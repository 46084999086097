.goalsperGame-graph {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 10px;
}
.goalsperGame-content {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.goalsperGame-teamNames {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  font-size: 20px;
}
.goalsperGame-graph .graphTitle {
  width: 156px;
  text-align: center;
  margin: 0 30px;
  font-family: HelveticaCondensed;
  font-size: 26px;
  line-height: 36px;
  text-transform: uppercase;
  span {
    font-size: 25px;
    text-transform: none;
  }
}
.highcharts-title {
  font-family: HelveticaCondensed;
  font-weight: bold;
  font-size: 20px !important;
}
.homeGoals-data, .awayGoals-data {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  .home-text {
    color: #09b3c6;
    font-size: 22px;
    max-width: 170px;
    text-align: center;
  }
  .away-text {
    color: #f38182;
    font-size: 22px;
    max-width: 170px;
    text-align: center;
  }
}
.homeGoals-circle,
.awayGoals-circle {
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}
.home-text {
  color:$homeTeamColor;
}
.away-text {
  color:$awayTeamColor;
}
.homeGoals-circle, .awayGoals-circle {
  width: 100px;
  height: 100px;
  font-size: 40px;
  &.circle {
    width: 125px !important;
    height: 125px !important;
    font-size: 45px;
  }
}
.homeGoals-circle {
  background-color: $homeTeamColor;
}
.awayGoals-circle {
  background-color: $awayTeamColor;
}
.correctScore-graph {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 600px;
  justify-content: space-evenly;
}
svg{
  font-family: HelveticaCondensed !important;
}
.singleCentered-graph{
  // width: 1000px;
  display: flex;
  width:100%;
  justify-content: center;
  margin: 0 auto;
}
.cornersFor-pergame-margin{
  margin-right: 40px;
  @media screen and (max-width: $medium_Device){
    margin-right:0px;
  }
  @media screen and (max-width: $tab_Device){
    margin-right:0px;
  }
  }
.goalsPer-game-margin{
  margin-right:60px;
  @media screen and (max-width: $medium_Device){
    margin-right:0px;
  }
  @media screen and (max-width: $tab_Device){
    margin-right:0px;
  }
}