@font-face {
  font-family: 'hkjc';
  src:  url('#{$fonts-path}/hkjc.eot?hcwnn0');
  src:  url('#{$fonts-path}/hkjc.eot?hcwnn0#iefix') format('embedded-opentype'),
    url('#{$fonts-path}/hkjc.woff?hcwnn0') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'hkjc' !important;
  // speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: middle;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-earth:before {
  content: "\e900";
}
.icon-clock:before {
  content: "\e901";
}
.icon-notification:before {
  content: "\e902";
}
.icon-arrow-left:before {
  content: "\e903";
}