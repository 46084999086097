.headerDetails {
  display: flex;
  flex-direction: row;
  font-size: 15px;
  font-weight: 600;
//   width: 100%;
  padding: 5px 25px;
  .haed-playerRank {
      width: 465px;
  }
  .haed-playerInfo {
      flex: 1 1 auto;
  }
  .haed-goalsNumber {
      width: 340px;
      text-align: center;
  }
  .haed-playerHkjcno {
      width: 175px;
      text-align: center;
  }
}
.topScore-playerdetails {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #fff;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  height: 150px;
  position: relative;
  border-bottom: solid 1px #ddd;
  .playerRank {
      position: relative;
      left: -27px;
      transform: skew(-15deg);
      padding: 3px 5px;
      font-size: 13px;
      font-weight: 600;
      background-color: #09b3c6;
      width: 170px;
      display: flex;
      align-self: stretch;
      align-items: center;
      justify-content: center;
      font-weight: 600;
      font-size: 30px;
      color: #fff;
      position: relative;
  }
  .playerIcon {
      background-color: #fff;
      width: 150px;
      transform: skew(-15deg);
      margin: 0 20px;

      .playerIcon-position {
          transform: skew(15deg);
      }
  }
  .playerCountry {
      background-color: #fff;
      position: absolute;
      left: 400px;
      @media screen and (max-width: $medium_Device){
        left: 325px;
    }
      @media screen and (max-width: $tab_Device){
        left: 250px;
      }
  }
  .playerInfo {
      color: #000;
      display: flex;
      background-color: #fff;
      width: 350px;
      padding: 0 10px 0 120px;
      flex: 1 1 auto;
      flex-direction: column;
      font-size: 20px;
      font-weight: 600;
      line-height: 25px;
      @media screen and (max-width: $tab_Device){
        font-size: 17px;
        line-height: 20px;
      }
      .text-blue {
          color: #001a69;
          text-transform: uppercase;
          font-size: 16px;
          @media screen and (max-width: $tab_Device){
            font-size: 15px;
          }
      }
  }
  .playerGoals {
      display: flex;
      justify-content: space-between;
      bottom: 10px;
      right: 10px;
      text-align: center;
      width: 180px;
      .goalsNumber {
          border-radius: 50%;
          background-color: #ff6f29;
          border: solid 1px #ccc;
          width: 60px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 35px;
          font-weight: 600;
          color: #fff;
          @media screen and (max-width: $tab_Device){
            width: 50px;
            height: 50px;
            font-size: 30px;
          }
      }
      .crossline {
          display: flex;
          width: 3px;
          border-left: 2px dotted #ccc;
          transform: skew(-15deg);
          border-radius: 30px;
      }
  }
  .playerHkjcno {
      background-color: #fff;
      width: 220px;
      font-weight: 600;
      font-size: 25px;
      text-align: center;
      color: #001a69;
      @media screen and (max-width: $tab_Device){
        font-size: 23px;
    }
  }
}
.w-400{
    width: 315px;
}
.countryLogo-img{
	width: 40px;
	height: auto;
    @media screen and (max-width: $tab_Device){
        width: 35px;
    }
}

// .topScorer-container {
//   padding: 0 20px 20px;
//   color: #fff;
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   .playerList {
//       display: flex;
//       flex-direction: column;
//       width: 250px;
//       margin: 0 5px;
//       margin-bottom: 20px;
//   }
//   .playerPhoto {
//       position: relative;
//       .goals {
//           position: absolute;
//           bottom: 10px;
//           right: 10px;
//           text-align: center;
//           .goalsHeading {
//               color: #ff6f29;
//               font-size: 16px;
//               font-weight: 600;
//           }
//           .goalsNumber {
//               border-radius: 50%;
//               background-color: $tertiaryColor;
//               border: solid 1px #ccc;
//               width: 50px;
//               height: 50px;
//               display: flex;
//               justify-content: center;
//               align-items: center;
//               font-size: 25px;
//               font-weight: 600;
//           }
//       }
//       img {
//           width: 250px;
//       }
//   }
//   .playerName {
//       background-color: $tertiaryColor;
//       height: 40px;
//       display: flex;
//       align-items: center;
//       padding: 5px 10px;
//       font-size: 25px;
//       font-weight: 600;
//   }
//   .playerDetails {
//       background-color: #fff;
//       padding: 10px;
//       color: #001a69;
//       line-height: 35px;
//       font-weight: 600;
//       text-transform: uppercase;
//       font-size: 17px;
//       .teamName img {
//           margin-right: 10px;
//       }
//       .teamPosition {
//           font-size: 20px;
//           margin-top: 20px;
//       }
//   }
// }