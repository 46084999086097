.btn-group {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    button {
        background-color: $background-white;
        border: 0px solid #ddd;
        color: $dark-blue;
        padding: 12px 5px;
        cursor: pointer;
        font-size: 17px;
        width: 175px;
        font-weight: 600;
        @media screen and (max-width: $tab_Device){
            padding: 8px 5px;
            font-size: 15px;
          }

        &:hover {
            text-decoration: none;
        }
        &.active {
            background-color: $tertiaryColor;
            color: $white;
            font-weight: 600;
        }
    }
    > button {
        &:not(:last-child) {
            border-right: none;
        }

        &:first-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        &:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
    a {
        &:not(:last-child) button {
            border-right: none;
        }

        &:first-child button {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        &:last-child button {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }
    }
    .buttonAll,
    .buttonHome {
        width: 33.3%;
        padding: 8px 5px;
        @media screen and (max-width: $tab_Device){
            padding: 6px;
        }
    }
    .buttonAway {
        width: 33.4%;
        padding: 8px 5px;
    }
}
.btn {
    border: none;
    padding: 8px;
    text-align: center;
    text-decoration: none;
    font-size: 15px;
    cursor: pointer;
    font-weight: 600;
    border-radius: 3px;
    background: #fff;
    min-width: 200px;
    &.active {
        background-color: $tertiaryColor;
        color: $white;
    }
}