.dualGrah {
  display: flex;
  flex-direction: column;
}
.dualHilo-table {
  margin: 0 100px;
}
.statisticsTables {
  thead th {
    padding: 8px 7px;
  }
  th:nth-child(1) {
    text-align: left;
  }
  tbody
  td {
    height: 35px;
  }
  padding: 20px 100px;
  @media screen and (max-width: $medium_Device){
    padding: 20px;
  }
  @media screen and (max-width: $tab_Device){
    padding: 10px;
  }
  td:nth-child(1) {
    text-align: left;
  }
}
.white-bg {
  background-color: #fff;
  span{
    font-size: 12px;
    text-transform: lowercase;
  }
}
.resultsData{
  height:90px;
}
